// import "@bam-spark/nav-toggle";
require('waypoints/lib/noframework.waypoints.js');
require('headroom.js/dist/headroom.min.js');

import Headroom from "headroom.js/dist/headroom.min.js";
const header = document.querySelector(".c-header__wrapper");
var options = {
    offset : 47,
}

const headroom = new Headroom(header, options);
headroom.init();


let toggle = document.querySelector('.js-nav-toggle');

toggle.addEventListener('click', function () {
  // let scrollPosition = window.scrollY;

  if (toggle.classList.contains('is-active')) {
    // Close Toggle
    toggle.classList.remove('is-active');
    document.body.classList.remove("menu-open");
    this.setAttribute('aria-expanded', 'false');

    // let top = document.body.style.top;
    // document.body.style.position = '';
    // document.body.style.top = '';
    // window.scrollTo(0, parseInt(top || '0') * -1);
  } else {
    // Open Toggle
    toggle.classList.add('is-active');
    document.body.classList.add("menu-open");
    this.setAttribute('aria-expanded', 'true');
    //
    // document.body.style.position = 'fixed';
    // document.body.style.top = `-${scrollPosition}px`;
  }
});

//mobile nav drop down
var mobileNav = document.querySelectorAll('.has-dropdown');

for (var i = 0; i < mobileNav.length; i++ ) {
  var mobileList = mobileNav[i];

  var dropdownToggle = mobileList.querySelector('.js-dropdown-toggle');

  dropdownToggle.addEventListener('click', function() {
    var dropMenu = this.nextElementSibling;

    if (this.classList.contains('plus-active')) {
      this.classList.remove('plus-active');
    } else {
      // Open Toggle
      this.classList.add('plus-active');
    }

    if (dropMenu.classList.contains('dropdown-active')) {
       // Close Toggle
        dropMenu.classList.remove('dropdown-active');
        dropMenu.setAttribute('aria-expanded', 'false');

      } else {
        // Open Toggle
        dropMenu.classList.add('dropdown-active');
        dropMenu.setAttribute('aria-expanded', 'true');
      }
  });
};



var elements = document.getElementsByClassName('js-animate');

for (var i = 0; i < elements.length; i++) {
    var element = elements[i];
    element.className += ' js-animate--pending';
}

var continuousElements = document.getElementsByClassName('js-animate')
for (var i = 0; i < continuousElements.length; i++) {
  new Waypoint({
    element: continuousElements[i],
    handler: function() {
      this.element.classList.add("js-animate--start")
  },
    offset: '97%'
  })
}

var lazyLoadInstance = new LazyLoad({
    elements_selector: ".js-lazy"
});
